import React from 'react';
import styled from 'styled-components';
import Colors from '../../components/common/Colors';
import Fonts from '../../components/common/Fonts';
import { useNavigate, Link } from 'react-router-dom';
import Logo from '../../components/basic/Logo';
import Button from '../../components/basic/Button';

const Footer = styled.div<{ $padding: string; $maxWidth?: number }>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    max-width: ${(props) => props.$maxWidth && `${props.$maxWidth}px` || '100%'};
    min-height: fit-content;
    flex: 1;
    background-color: ${Colors.background};
    padding: ${(props) => props.$padding};
    margin: 0;
`;

const FooterContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 10px;
`;

const Row = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
`;

const DividerLine = styled.hr`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    border-top: 1px solid ${Colors.grey1};
    width: 100%;
`;

interface CalculatorFooterProps {
    padding: string;
    maxWidth?: number;
}

const CalculatorFooter = ({ padding, maxWidth }: CalculatorFooterProps) => {
    const navigate = useNavigate();

    const onLogoClick = () => {
        navigate("/problem");
    };

    const onDiscordClick = () => {
        window.open('https://discord.gg/96PVDXMhkc', '_blank');
    };

    return (
        <Footer id="footer" $padding={padding} $maxWidth={maxWidth}>
            <FooterContent>
            <DividerLine/>
                <Row>
                    <Logo size={20} onClick={onLogoClick}/>
                    <Button size={'small'} icon={'discord'} color={'ghost'} onClick={onDiscordClick} />
                </Row>
            </FooterContent>
        </Footer>
    );
};

export default CalculatorFooter;


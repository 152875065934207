// TransformContent.tsx
import { round } from "lodash";
import Colors, { getComplementaryColors, getColorViz } from "../../components/common/Colors";
import { create, all } from 'mathjs';
import { smartRound, simplifyFraction, fractionToMixed, fractionString, greatestCommonFactor, getFactors, commaForm, largeValueString } from '../../utils/mathHelpers';

const math = create(all);

export const ensureName = (obj) => {
    if (!obj.name) {
        if (obj.parts && obj.parts.length > 0) {
            obj = {
                ...obj,
                name: String(obj.parts[0]?.text).slice(0, 30) + "..."
            };
        } else if (obj.text) {
            obj = {
                ...obj,
                name: String(obj.text).slice(0, 30) + "..."
            };
        }
    }
    return obj;
};

export const transformInitialContentFormat = (problem) => {
    const ensurePartsAndParse = (obj) => {
        const ensureArray = (value) => {
            if (typeof value === 'string') {
                try {
                    return JSON.parse(value);
                } catch (error) {
                    console.error("Error parsing JSON string:", value, error);
                    return [];
                }
            }
            return Array.isArray(value) ? value : [value];
        };

        const parseJson = (obj) => {
            try {
                const parsed = JSON.parse(obj);
                return parsed;
            } catch (error) {
                // console.error('Error parsing JSON string:', obj, error);
                return obj;
            }
        };

        if (obj.parts) {
            obj.parts = ensureArray(obj.parts);
        } else {
            obj = {
                ...obj,
                parts: [{
                    text: obj.text || '',
                    expressions: ensureArray(obj.expressions || []),
                    visualizations: ensureArray(obj.visualizations || []),
                    solution: parseJson(obj.solution) || null,
                    answer: parseJson(obj.answer) || null,

                    // calculators
                    inputs: parseJson(obj.inputs) || null,
                    answers: parseJson(obj.answers) || null,
                    content: parseJson(obj.content) || null,
                    side_visualizations: ensureArray(obj.side_visualizations || []),
                }]
            };
            delete obj.text;
            delete obj.expressions;
            delete obj.visualizations;
            delete obj.solution;
            delete obj.answer;

            // calculators
            delete obj.inputs;
            delete obj.answers;
            delete obj.content;
            delete obj.side_visualizations;
        }

        if (obj.variables) obj.variables = parseJson(obj.variables);
        if (obj.experimental) obj.experimental = parseJson(obj.experimental);
        
        return obj;
    };

    const initializeLogicArrays = (obj, path = '') => {
        const hasTemplateLiteral = (str) => 
            typeof str === 'string' && str.includes('${') && str.includes('}');

        const findArraysWithTemplates = (obj, path = '') => {
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    const value = obj[key];
                    if (Array.isArray(value)) {
                        // Check if any item in the array has a template literal
                        const hasTemplates = value.some(item => {
                            if (typeof item === 'string') {
                                return hasTemplateLiteral(item);
                            }
                            // Also check nested arrays
                            if (Array.isArray(item)) {
                                return item.some(subItem => hasTemplateLiteral(subItem));
                            }
                            // Check object values
                            // if (typeof item === 'object' && item !== null) {
                            //     return Object.values(item).some(val => hasTemplateLiteral(val));
                            // }
                            return false;
                        });

                        if (hasTemplates) {
                            const logicKey = key + 'Logic';
                            if (!obj[logicKey] || obj[logicKey].length !== value.length) {
                                // console.log(`Initializing Logic array for ${path ? path + '.' : ''}${key} with length ${value.length}`);
                                obj[logicKey] = new Array(value.length).fill(null);
                            }
                        }
                    }
                    if (typeof value === 'object' && value !== null) {
                        findArraysWithTemplates(value, path ? `${path}.${key}` : key);
                    }
                }
            }
        };
        findArraysWithTemplates(obj);
        return obj;
    };

    const setGlobalColors = (problem) => {
        if (problem.numColors === undefined || problem.numColors === null || problem.numColors === 0) {
            if ((problem.status === 'SUCCESS' || !problem.status) && (problem.visualizations?.length > 0 || problem.parts?.some(part => part.visualizations?.length > 0))) {
                console.error('numColors is invalid', problem.id, problem);
            }
        };

        if (problem.colorOverride && problem.colorOverride.length > 0 === false) {
            problem.colorOverride = null;
        }

        if (problem.numColors) {
            const complementaryColors = getComplementaryColors(problem.numColors);
            let colors = complementaryColors;

            const overrideColors = (complementaryColors: string[], problem: any): string[] => {
                if (!complementaryColors || !Array.isArray(complementaryColors)) return [];
                if (problem?.colorOverride) {
                  return problem.colorOverride.map((color: string) => getColorValueByName(color));
                }
                return complementaryColors;
            };

            if (problem.colorOverride) {
                colors = overrideColors(complementaryColors, problem);
            }
            if (!problem.colors) {
                problem.colors = colors;
            }
            // console.log('Setting global colors', problem.id, colors);
        }
        return problem;
    };

    const transformVisualizations = (parts, colors) => {
        const transformLineGraphEquations = (vis) => {
            if (vis.datasets?.some(dataset => dataset.equation && (!dataset.points || dataset.points.length === 0))) {
                // if (problem.id === CONSOLE_LOG_ID) console.log('transforming line graph equations');
                const calculatePoint = (equation: string, x: number) => {
                    try {
                        // Extract the right side of the equation after "y ="
                        const rightSide = equation.split('=')[1]?.trim() || equation;
                        const scope = { x };

                        // Evaluate using math.js
                        const y = math.evaluate(rightSide, scope);
                        return { x, y: Number(y) };
                    } catch (error) {
                        console.error('Error evaluating equation:', error);
                        return { x, y: NaN };
                    }
                };
    
                let transformedViz = { ...vis };
                if (Array.isArray(transformedViz.datasets)) {
                    transformedViz.datasets = transformedViz.datasets.map((dataset, datasetIndex) => {
                        if (dataset.equation) {
                            // if (problem.id === CONSOLE_LOG_ID) console.log(`Dataset ${datasetIndex} has equation:`, dataset.equation);
                            const xMin = transformedViz.xAxis.min;
                            const xMax = transformedViz.xAxis.max;
                            const numPoints = 99; // Increase number of points for smoother curve
                            const step = (xMax - xMin) / (numPoints - 1);
                            
                            let points = [];
                            for (let i = 0; i < numPoints; i++) {
                                const x = xMin + i * step;
                                points.push(calculatePoint(dataset.equation, x));
                            }
                            points = points.filter(point => isFinite(point.y)); // Filter out any NaN or infinite values
                            
                            dataset.points = points;
                            // if (problem.id === CONSOLE_LOG_ID) console.log('Dataset', datasetIndex, 'points', points);
                        }
                        return dataset;
                    });
                }
                return transformedViz;
            } else {
                // console.log('No datasets with equations and empty points found');
                return vis;
            }
        };

        const transformGridCounters = (counters: any) => {
            let newCounters = [];
            if (Array.isArray(counters[0])) { // Already nested, no transformation needed
                newCounters = counters;
            } else {
                newCounters = [counters]; // Nest the counters inside another array
            }
            return newCounters;
        };

        const transformNumberBarCircleInitial = (vis) => {
            const transformedVis = { ...vis };
            if (!vis.tickInitial && vis.tick) {
                transformedVis.tickInitial = vis.tick;
            }
            if (!vis.totalValueInitial) {
                transformedVis.totalValueInitial = vis.totalValue;
            }
            // consoleLogId(problem.id, `number bar ${JSON.stringify(transformedVis)}`);
            return transformedVis;
        };

        const transformImages = (vis) => {
            const findImageFields = (obj, path) => {
                for (const key in obj) {
                    if (obj.hasOwnProperty(key)) {
                        const value = obj[key];
                        const fullPath = path ? `${path}.${key}` : key;
                        const baseKey = `${key}Base`;

                        // Check if the image has already been transformed
                        if (/image/i.test(key) && !key.endsWith("Base")) {
                            // Skip if the imagesBase key already exists
                            if (!obj.hasOwnProperty(baseKey)) {
                                if (vis.type === 'numberLine') {
                                    obj[baseKey] = Object.freeze(value.map(imageObj => getImageBasePath(imageObj.name)));
                                } else {
                                    if (Array.isArray(value)) {
                                        obj[baseKey] = Object.freeze(
                                            value.map((item) =>
                                                Array.isArray(item)
                                                    ? item.map(innerItem => getImageBasePath(innerItem))
                                                    : getImageBasePath(item)
                                            )
                                        );
                                    } else {
                                        obj[baseKey] = Object.freeze(getImageBasePath(value));
                                    }
                                }
                            }
                        }

                        if (typeof value === 'object' && value !== null) {
                            findImageFields(value, fullPath);
                        }
                    }
                }
            };

            findImageFields(vis, vis);
            return vis;
        };

        return parts.map(part => {
            const transformedVisualizations = part.visualizations?.map(vis => {
                vis = transformColors(vis, colors);
                vis = transformImages(vis);

                if (vis.type === 'lineGraph') {
                    vis = transformLineGraphEquations(vis);
                } else if (vis.type === 'grid') {
                    vis = {
                        ...vis,
                        counters: transformGridCounters(vis.counters)
                    };
                } else if (vis.type === 'numberBar' || vis.type === 'numberCircle') {
                    vis = transformNumberBarCircleInitial(vis);
                }
                return vis;
            });

            const transformedSideVisualizations = part.side_visualizations?.map(vis => {
                vis = transformColors(vis, colors);
                vis = transformImages(vis);

                if (vis.type === 'lineGraph') {
                    vis = transformLineGraphEquations(vis);
                } else if (vis.type === 'grid') {
                    vis = {
                        ...vis,
                        counters: transformGridCounters(vis.counters)
                    };
                } else if (vis.type === 'numberBar' || vis.type === 'numberCircle') {
                    vis = transformNumberBarCircleInitial(vis);
                }
                return vis;
            });

            return {
                ...part,
                visualizations: transformedVisualizations,
                side_visualizations: transformedSideVisualizations
            };
        });
    };

    const transformExpressions = (parts, colors) => {
        return parts.map(part => {
            if (part.expressions) {
                const transformedExpressions = part.expressions.map(expr => {
                    expr = transformColors(expr, colors);
                    return expr;
                });

                return {
                    ...part,
                    expressions: transformedExpressions
                };
            }
            return part; // Return the part unchanged if expressions don't exist
        });
    };    

    const transformColors = (obj, colors) => {
        const findColorFields = (obj, path) => {
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    const value = obj[key];
                    const fullPath = path ? `${path}.${key}` : key;

                    // Check if the color has already been transformed
                    if (/color/i.test(key) && !key.endsWith("Original")) {
                        if (Array.isArray(value)) {
                            obj[`${key}Original`] = Object.freeze(
                                value.map(item =>
                                    Array.isArray(item)
                                        ? item.map(innerItem => getColorFromIndex(innerItem, colors))
                                        : getColorFromIndex(item, colors)
                                )
                            );
                        } else {
                            obj[`${key}Original`] = Object.freeze(getColorFromIndex(value, colors));
                        }
                    }

                    if (typeof value === 'object' && value !== null) {
                        findColorFields(value, fullPath);
                    }
                }
            }
        };        

        const getColorFromIndex = (colorIndex, colors) => {
            let color;
            if (colorIndex) {
                if (typeof colorIndex !== 'number' || isNaN(colorIndex)) {
                    color = colorIndex;
                    // console.warn('COLOR IS ALREADY STRING');
                } else if (colorIndex === -1) {
                    color = Colors.grey6;
                } else if (colorIndex === undefined || colorIndex === null || colors === undefined || colors === null) {
                    color = Colors.vizDefault;
                } else {
                    color = colors[colorIndex - 1];
                }
                // console.log('COLOR', problem.id, colorIndex, color, 'global colors', colors);
                return color;
            }
        };

        findColorFields(obj, obj); // Start the recursion from the root of the visualization object
        return obj;
    };

    const parseLogic = (problem) => {
        const findAndStoreTemplateLiterals = (obj, path = '', parent = null) => {
            if (typeof obj === 'string' && obj.includes('${') && obj.includes('}')) {
                if (path.startsWith('variables.') && obj.includes('parts[')) {
                    // if (problem.id === CONSOLE_LOG_ID) console.warn('Skipping', '\npath', path, '\nobj', obj);//, '\nparent', parent);
                } else {
                    // if (problem.id === CONSOLE_LOG_ID) console.log('CREATING LOGIC', '\npath', path, '\nobj', obj)//, '\nparent', parent);
                    try {
                        const parentPath = path.split('.').slice(0, -1).join('.');
                        const key = path.split('.').pop();
                        const baseKey = key.replace(/\[\d+\]$/, '');
                        // if (problem.id === CONSOLE_LOG_ID) console.log('baseKey', baseKey, 'key', key, 'parentPath', parentPath);

                        // Construct the new key name for logic fields
                        let logicKey;
                        const matchArrayIndex = key.match(/\[(\d+)\]$/);
                        if (matchArrayIndex) {
                            logicKey = baseKey.endsWith('Logic') ? `${baseKey}[${matchArrayIndex[1]}]` : `${baseKey}Logic[${matchArrayIndex[1]}]`;
                        } else {
                            logicKey = key.endsWith('Logic') ? key : `${key}Logic`;
                        }                    
                        // if (problem.id === CONSOLE_LOG_ID) console.log(`original key = ${parentPath}.${key}, logicKey = ${parentPath}.${logicKey}`);

                        const transformTemplateLiteral = (literal, parentPath, problem) => {
                            const transformLocal = (literal, parentPath) => {
                                if (Object.keys(problem.variables).some(key => literal.includes(key))) {
                                    // if (problem.id === CONSOLE_LOG_ID) console.log('SKIPPING REPLACE', 'literal includes a variable', literal);
                                    return literal;
                                } else {
                                    const result = literal.replace(/\$\{([a-zA-Z0-9_\[\]\.]+)\}/g, (_, localVar) => {
                                        // If it's a pure number or mathematical expression, return as is
                                        if (/^[0-9\s\+\-\*\/\(\)\.]+$/.test(localVar)) {
                                            return `\${${localVar}}`;
                                        }
                                        // Handle dot notation
                                        if (localVar.includes('.')) {
                                            return`\${${localVar}}`;
                                        }
                                        
                                        const fullPath = `${parentPath}.${localVar}`;
                                        try {
                                            eval(`problem.${fullPath}`);
                                            return `\${${fullPath}}`;
                                        } catch (e) {
                                            console.error(`Error evaluating variable at path: ${fullPath}. Reverting to original.`, e);
                                            return literal;
                                        }
                                    });
                                    // if (problem.id === CONSOLE_LOG_ID) console.log('REPLACING','literal', literal, 'result', result);
                                    return result;
                                }
                            };
                            let transformed = transformLocal(literal, parentPath);
                            // if (problem.id === CONSOLE_LOG_ID && transformed !== literal) console.log('TRANSFORMED literal', literal, 'into', transformed);
                            return transformed;
                        };
                        const transformedLiteral = transformTemplateLiteral(obj, parentPath, problem);

                        const parentObj = path ? eval(`problem.${parentPath}`) : problem;
                        // console.log(`parent path ${parentPath} \nparent obj ${JSON.stringify(parentObj)} \npath ${path} \n base key ${baseKey} \nID: ${problem.id}`);

                        // Store the transformed logic in the new key, do not alter the original key
                        if (Array.isArray(parentObj[baseKey])) {
                            const index = parseInt(key.replace(/\D/g, ''), 10);
                            // console.log(`Array ${logicKey} = ${transformedLiteral} \nID: ${problem.id} `);
                            
                            // Remove the array index part from logicKey
                            const logicKeyWithoutIndex = logicKey.endsWith('Logic') ? logicKey : logicKey.replace(/\[\d+\]$/, '');
                            // console.log(`logic key ${logicKey} without index ${logicKeyWithoutIndex} \nID: ${problem.id}`);

                            // Create the array if it doesn't already exist
                            if (!parentObj[logicKeyWithoutIndex]) {
                                parentObj[logicKeyWithoutIndex] = [];
                                // console.log(`CREATED THE ARRAY ${parentPath}.${logicKeyWithoutIndex} = [] \nID: ${problem.id}`);
                            }
                            
                            // Assign the transformed literal and freeze the object
                            parentObj[logicKeyWithoutIndex][index] = Object.freeze(transformedLiteral);
                            // console.log(`Stored value at ${parentPath}.${logicKeyWithoutIndex}[${index}]: ${parentObj[logicKeyWithoutIndex][index]}`);
                        } else {
                            // console.log(`New key ${logicKey} = ${transformedLiteral} \nID: ${problem.id} `);
                            
                            // Assign the transformed literal and freeze the object
                            parentObj[logicKey] = Object.freeze(transformedLiteral);
                            // console.log(`Stored value: ${parentObj[logicKey]}`);
                        }
                    } catch (error) {
                        console.error(`Error processing template literal at ${path}: ${obj}`, error, "Parent object:", parent);
                    }
                }
            } else if (Array.isArray(obj)) {
                obj.forEach((item, index) => {
                    findAndStoreTemplateLiterals(item, `${path}[${index}]`, obj);
                });
            } else if (typeof obj === 'object' && obj !== null) {
                for (const key in obj) {
                    if (obj.hasOwnProperty(key)) {
                        findAndStoreTemplateLiterals(obj[key], path ? `${path}.${key}` : key, obj);
                    }
                }
            }
        };

        const moveLogic = (problem) => {
            const basePath = problem.variables;
            for (const key in basePath) {
                if (basePath.hasOwnProperty(key)) {
                    const refKey = key + 'Ref';
                    const logicKey = key + 'Logic';
                    if (basePath[key] && basePath[refKey] && basePath[logicKey]) {
                        const baseValue = basePath[key];
                        const refValue = basePath[refKey];
                        const logicValue = basePath[logicKey];
                        // if (problem.id === CONSOLE_LOG_ID) console.log('FOUND', key, '=', '\nbase', baseValue, '\nref', refValue, '\nlogic', logicValue);
                        
                        const refPath = refValue.replace(/\${/, '').replace(/}$/, '');
                        let newLogicPath;
                        if (refPath.endsWith(']')) {
                            const lastBracketIndex = refPath.lastIndexOf('[');
                            const arrayIndex = refPath.slice(lastBracketIndex);
                            const pathWithoutIndex = refPath.slice(0, lastBracketIndex);
                            newLogicPath = pathWithoutIndex + 'Logic' + arrayIndex;
                        } else {
                            newLogicPath = refPath + 'Logic';
                        }
                        // if (problem.id === CONSOLE_LOG_ID) console.log('refPath', refPath, '\nnewLogicPath', newLogicPath);

                        // Add logic field at the proper location
                        const pathParts = newLogicPath.split('.');
                        let currentObj = problem;
                        for (let i = 0; i < pathParts.length - 1; i++) {
                            const part = pathParts[i];
                            const match = part.match(/(\w+)\[(\d+)\]/);
                            if (match) {
                                const [, arrayName, index] = match;
                                if (!currentObj[arrayName]) currentObj[arrayName] = [];
                                if (!currentObj[arrayName][index]) currentObj[arrayName][index] = {};
                                currentObj = currentObj[arrayName][index];
                            } else {
                                if (!currentObj[part]) currentObj[part] = {};
                                currentObj = currentObj[part];
                            }
                        }
                        const lastPart = pathParts[pathParts.length - 1];
                        const lastMatch = lastPart.match(/(\w+)\[(\d+)\]/);
                        if (lastMatch) {
                            const [, arrayName, index] = lastMatch;
                            if (!currentObj[arrayName]) currentObj[arrayName] = [];
                            currentObj[arrayName][index] = logicValue;
                        } else {
                            currentObj[lastPart] = logicValue;
                        }

                        // Remove logic field from variables
                        delete basePath[logicKey];
                    }
                }
            }
        };
    
        findAndStoreTemplateLiterals(problem);
        moveLogic(problem);
        return problem;
    };  
    
    const parseVars = (problem) => {
        const findAndStoreVars = (obj, path = '') => {
            if (typeof obj === 'string' && path.toLowerCase().includes('var')) {
                // console.log(`VARIABLE found at path ${path} = ${obj}`);
                const varName = obj;
                let fullPath = path.replace(/Var$/, '');
                if (fullPath.endsWith(']')) {
                    const index = parseInt(fullPath.slice(fullPath.lastIndexOf('[') + 1, -1), 10);
                    fullPath = fullPath.replace(/Var\[.*?\]$/, '');
                    fullPath = fullPath + '[' + index + ']';
                }
                // if (problem.id === CONSOLE_LOG_ID) console.log('varName', varName, 'fullPath', fullPath);
                
                if (varName.startsWith('${') && varName.endsWith('}')) {
                    const pathWithoutVariables = path.replace('variables.', '');
                    jsonVariables[pathWithoutVariables] = varName;
                    // if (problem.id === CONSOLE_LOG_ID) console.warn(`skipping json-defined variable: ${varName} at path: ${pathWithoutVariables}`);
                } else {
                    const newVal = "${" + fullPath + "}";
                    const varRefName = varName + 'Ref';
                    variables[varName] = newVal;
                    variables[varRefName] = newVal;
                    // console.log('Creating ref', `variables.${varName} = ${variables[varName]}`, `variables.${varRefName} = ${variables[varRefName]}`);
                }
            } else if (Array.isArray(obj)) {
                obj.forEach((item, index) => {
                    findAndStoreVars(item, `${path}[${index}]`);
                });
            } else if (typeof obj === 'object' && obj !== null) {
                for (const key in obj) {
                    if (obj.hasOwnProperty(key)) {
                        findAndStoreVars(obj[key], path ? `${path}.${key}` : key);
                    }
                }
            }
        };
        
        const variables = {};
        const jsonVariables = {};
        findAndStoreVars(problem);

        const allVariables = { ...variables, ...jsonVariables };
        let updatedProblem = { ...problem, variables: allVariables };

        // if (problem.id === CONSOLE_LOG_ID) {
        //     console.log('all variables', allVariables);
        //     console.log('updatedProblem', updatedProblem);
        // }
        
        return updatedProblem;
    };

    // if (problem.id === CONSOLE_LOG_ID) console.log("Before transform", problem); 
    let updatedProblem = { ...problem };
    updatedProblem = ensureName(updatedProblem);
    updatedProblem = ensurePartsAndParse(updatedProblem);
    updatedProblem = initializeLogicArrays(updatedProblem);
    updatedProblem = setGlobalColors(updatedProblem);
    // if (updatedProblem.id === CONSOLE_LOG_ID) console.log("Before expr and viz", updatedProblem); 
    updatedProblem.parts = transformExpressions(updatedProblem.parts, updatedProblem.colors); 
    updatedProblem.parts = transformVisualizations(updatedProblem.parts, updatedProblem.colors);
    updatedProblem = parseVars(updatedProblem);  
    updatedProblem = parseLogic(updatedProblem);
    // if (updatedProblem.id === CONSOLE_LOG_ID) console.log("Before transform", updatedProblem); 
    updatedProblem = transformContentEveryUpdate(updatedProblem);
    if (updatedProblem.computeQuestionResponse) {
        delete updatedProblem.computeQuestionResponse;
    }

    // if (updatedProblem.id === CONSOLE_LOG_ID) console.log("INITIAL Transformed Problem Data", updatedProblem); 
    return updatedProblem;
};

export const transformContentEveryUpdate = (problem, hoveredColor = null, skipLogic?) => {
    // consoleLogId(problem.id, `---------------------`);
    // consoleLogId(problem.id, `${problem.id} CALLING TRANSFORM PROBLEM EVERY UPDATE`);
    
    const transformVisualizations = (parts, colorOverride) => {
        const transformImages = (vis, colorOverride) => {
            const findImageFields = (obj, path = '') => {
                for (const key in obj) {
                    if (obj.hasOwnProperty(key)) {
                        const value = obj[key];
                        const fullPath = path ? `${path}.${key}` : key;

                        if (/image/i.test(key) && !/imageBase/i.test(key)) { // Check if the field name includes "image" and does not include "Base" (case insensitive)
                            const baseKey = key.endsWith('Base') ? fullPath : `${fullPath}Base`; // Append "Base" only if it's not already appended
                            const baseValue = obj[baseKey.split('.').pop()]; // Retrieve the imageBase value
                            const colorKey = fullPath.replace(/image/i, 'color'); // Determine the corresponding color path
                            const colorValue = obj[colorKey.split('.').pop()]; // Find the color value at the same level
                            // console.log('found image and image base', baseKey, baseValue, colorKey, colorValue);
                            
                            if (vis.type === 'numberLine') {
                                value.forEach((item, index) => {
                                    if (baseValue?.[index] && item.name && item.color){
                                        let newPath = getImageFullPath(
                                            baseValue?.[index], // Use the base image at the same index
                                            item.color, // Access the color property directly from the item
                                            colorOverride
                                        );
                                        // console.log('updated image', 
                                        //     {baseImageName: baseValue?.[index],
                                        //     color: item.color,
                                        //     colorOverride}, 
                                        //     'name', item.name, 
                                        //     'newpath', newPath);
                                        item.name = newPath;
                                        // console.log('updated', item.name);
                                    }
                                });
                            } else {
                                if (Array.isArray(value)) {
                                    obj[key] = value.map((item, index) =>
                                        Array.isArray(item)
                                            ? item.map((innerItem, innerIndex) => getImageFullPath(baseValue[index][innerIndex], colorValue && colorValue[index] && colorValue[index][innerIndex], colorOverride))
                                            : getImageFullPath(baseValue[index], colorValue && colorValue[index], colorOverride)
                                    );
                                } else {
                                    obj[key] = getImageFullPath(baseValue, colorValue, colorOverride);
                                }
                            }
                        }

                        if (typeof value === 'object' && value !== null) {
                            findImageFields(value, fullPath); // Recurse into nested objects
                        }
                    }
                }
            };

            findImageFields(vis); // Start the recursion from the root of the visualization object
            return vis;
        };

        return parts.map(part => {
            const transformedVisualizations = part.visualizations?.map(vis => {
                // if (vis.type === 'grid') console.log('grid', problem.id, vis);
                // if (vis.type === 'numberLine') console.log('numberLine', problem.id, vis);
                return {
                    ...vis,
                    ...transformColors(vis),
                    ...transformImages(vis, colorOverride)
                };
            });

            const transformedSideVisualizations = part.side_visualizations?.map(vis => {
                return {
                    ...vis,
                    ...transformColors(vis),
                    ...transformImages(vis, colorOverride)
                };
            });

            return {
                ...part,
                visualizations: transformedVisualizations,
                side_visualizations: transformedSideVisualizations
            };
        });
    };

    const transformExpressions = (parts) => {
        return parts.map(part => {
            if (part.expressions) {
                const transformedExpressions = part.expressions.map(expr => {
                    expr = transformColors(expr);
                    return expr;
                });
        
                return {
                    ...part,
                    expressions: transformedExpressions
                };
            }
            return part; // Return the part unchanged if expressions don't exist
        });
    };  

    const transformColors = (obj) => {
        const findAndUpdateColorFields = (obj) => {
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    const value = obj[key];

                    // Check if the field name includes "color" but does NOT include "Original"
                    if (/color/i.test(key) && !/Original/i.test(key)) {
                        const originalKey = `${key}Original`;
                        if (obj.hasOwnProperty(originalKey)) {
                            obj[key] = obj[originalKey]; // Set the color field equal to the corresponding Original field
                        }
                    }

                    if (typeof value === 'object' && value !== null) {
                        findAndUpdateColorFields(value); // Recurse into nested objects
                    }
                }
            }
        };

        findAndUpdateColorFields(obj); // Start the recursion from the root of the visualization object
        return obj;
    };

    const applyLogic = (problem, skipLogic) => {
        // consoleLogId(problem.id, `Starting APPLY LOGIC ${problem.id}`);
    
        const findLogicFields = (obj, path = '') => {
            const logicFields = [];
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    const value = obj[key];
                    const fullPath = path ? `${path}.${key}` : key;
                    const normalizedFullPath = convertBracketToDotSyntax(fullPath);
                    // consoleLogId(obj.id, `Inspecting key: ${key} at path: ${normalizedFullPath}`);
        
                    if (/Logic$/i.test(key)) {  // Check if the field name ends with "Logic"
                        const baseKey = key.replace(/Logic$/i, '');  // Remove "Logic" from the end of the key to get the corresponding field
                        const logicPath = normalizedFullPath;
                        const currentPath = path ? `${path}.${baseKey}` : baseKey;
                        const normalizedCurrentPath = convertBracketToDotSyntax(currentPath);
                        const logicValue = obj[key];
                        const currentFieldExists = obj.hasOwnProperty(baseKey);

                        const refKey1 = baseKey+'Var';
                        const refValue1 = obj[refKey1];
                        const refKey2 = baseKey+'Ref';
                        const refValue2 = obj[refKey2]?.replace(/\${|}/g, '');
                        const refValue = refValue1 || refValue2;
        
                        // if (problem.id === CONSOLE_LOG_ID) console.log(obj.id, `Found logic field: ${logicPath}`);
                        // if (problem.id === CONSOLE_LOG_ID) console.log('currentFieldExists', currentFieldExists, 'logicPath', logicPath, 'currentPath', currentPath, 'baseKey', baseKey, 'refKey', refKey, 'refValue', refValue);
        
                        if (currentFieldExists) {
                            // if (problem.id === CONSOLE_LOG_ID) console.log('refKey', refKey1, refKey2, 'refValue', refValue1, refValue2, '=', refValue)// 'normalizedCurrentPath', normalizedCurrentPath, 'obj', obj);
                            
                            const currentValue = obj[baseKey];
                            if (Array.isArray(logicValue) && Array.isArray(currentValue)) { // Handle array of logic fields
                                logicValue.forEach((logicItem, index) => {
                                    const arrayFieldPath = `${normalizedCurrentPath}[${index}]`;
                                    const logicArrayFieldPath = `${logicPath}[${index}]`;
        
                                    if (index < currentValue.length) {
                                        // consoleLogId(obj.id, `Adding logic field for ARRAY element: ${arrayFieldPath} = ${logicArrayFieldPath}`);
                                        logicFields.push({
                                            logicPath: convertBracketToDotSyntax(logicArrayFieldPath),
                                            currentPath: convertBracketToDotSyntax(arrayFieldPath),
                                            logicValue: logicItem,
                                            currentValue: currentValue[index],
                                            obj: currentValue,
                                            baseKey: index,
                                            ref: refValue,
                                            type: 'logic'
                                        });
                                    }
                                });
                            } else { // Handle non-array logic fields
                                // consoleLogId(obj.id, `Adding logic field: ${logicPath} = ${logicValue}`);
                                logicFields.push({
                                    logicPath: convertBracketToDotSyntax(logicPath),
                                    currentPath: normalizedCurrentPath,
                                    logicValue,
                                    currentValue,
                                    obj,
                                    baseKey,
                                    ref: refValue,
                                    type: 'logic'
                                });
                            }
                        }
                    }
        
                    if (typeof value === 'object' && value !== null) { // Recurse into nested objects
                        // consoleLogId(obj.id, `Descending into nested object at path: ${normalizedFullPath}`);
                        logicFields.push(...findLogicFields(value, normalizedFullPath));
                    }
                }
            }
            
            const orderLogicFields = (fields) => {
                return fields.sort((a, b) => {
                    if (a.logicPath.toLowerCase().includes('variables') && !b.logicPath.toLowerCase().includes('variables')) return -1;
                    if (!a.logicPath.toLowerCase().includes('variables') && b.logicPath.toLowerCase().includes('variables')) return 1;

                    if (a.logicPath.toLowerCase().includes('logic') && !b.logicPath.toLowerCase().includes('logic')) return -1;
                    if (!a.logicPath.toLowerCase().includes('logic') && b.logicPath.toLowerCase().includes('logic')) return 1;

                    const aContainsLabelOrName = /label|name/i.test(a.logicPath);
                    const bContainsLabelOrName = /label|name/i.test(b.logicPath);

                    if (aContainsLabelOrName && !bContainsLabelOrName) return 1;
                    if (!aContainsLabelOrName && bContainsLabelOrName) return -1;

                    return 0;
                });
            };
            const orderedLogicFields = orderLogicFields(logicFields);
            // if (obj.id === CONSOLE_LOG_ID) console.log('ORDERED LOGIC FIELDS', orderedLogicFields.length+1, obj.id, orderedLogicFields);
            return orderedLogicFields;
        };

        const findRefFields = (obj) => {
            const refFields = [];
            for (const key in obj) {
                if (obj.hasOwnProperty(key) && key.endsWith('Ref')) {
                    const currentKey = key.replace(/Ref$/, '');
                    refFields.push({
                        logicPath: key,
                        currentPath: currentKey,
                        logicValue: obj[key],
                        currentValue: obj[currentKey],
                        obj: obj,
                        type: 'ref'
                    });
                }
            }
            return refFields;
        };

        const checkSkipLogic = (path, skipLogic) => {
            if (skipLogic) {
                const normalizedSkipLogic = convertBracketToDotSyntax(skipLogic);
                const normalizedPath = convertBracketToDotSyntax(path);
                // consoleLogId(problem.id, `skipLogic: ${skipLogic}`);
                if (normalizedSkipLogic === normalizedPath) {
                    // consoleLogId(problem.id, `current location: ${path}`);
                    // consoleLogId(problem.id, `SKIPPING LOGIC: ${normalizedSkipLogic}`);
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        };
    
        const applyLogicFields = (logicFields, problem, skipLogic) => {
            logicFields.forEach((logicField) => {
                applyLogicField(logicField, problem, skipLogic);
                // if (problem.id === CONSOLE_LOG_ID) console.log('EVERY UPDATE', logicFields);
            });
        };

        const applyLogicField = (logicField, problem, skipLogic) => {
            const { logicPath, currentPath, logicValue, currentValue, obj, baseKey } = logicField;
            // if (problem.id === CONSOLE_LOG_ID) console.log('logicFields', logicPath, '\ncurrentPath', currentPath, '\nlogicValue', logicValue, '\ncurrentValue', currentValue, '\nobj', obj, '\nbaseKey', baseKey);
            
            if (logicValue === undefined || logicValue === null) return;
            try {
                const evalLogic = (logicValue, problem) => {
                    // if (problem.id === CONSOLE_LOG_ID) console.log('before logic eval', logicValue);
                    const safeEval = new Function('obj', 'math', 'smartRound', 'simplifyFraction', 'fractionToMixed', 'fractionString', 'greatestCommonFactor', 'getFactors', 'commaForm', 'largeValueString',  `
                        with (obj) {
                            try {
                                return \`${logicValue.replace(/\\/g, '\\\\')}\`;
                            } catch (e) {
                                console.warn('Error during evaluation:', e, logicValue);
                                return undefined;
                            }
                        }
                    `);
                    
                    let result;
                    if (typeof logicValue === 'string' && logicValue.includes('parts') === false && logicValue.includes('variables') === false) {
                        result = safeEval(problem.variables, math, smartRound, simplifyFraction, fractionToMixed, fractionString, greatestCommonFactor, getFactors, commaForm, largeValueString);
                        // if (problem.id === CONSOLE_LOG_ID) console.log('evalLogic NEW', problem.name, logicPath, logicValue, '=', result);
                    } else {
                        result = safeEval(problem, math, smartRound, simplifyFraction, fractionToMixed, fractionString, greatestCommonFactor, getFactors, commaForm, largeValueString);
                        if (result.includes('undefined')) console.warn('Result includes undefined', 'logicPath', logicPath, 'logicValue', logicValue, 'result', result);
                        // if (problem.id === CONSOLE_LOG_ID) console.log('evalLogic OLD', logicPath, logicValue, result);
                    }
                    // if (problem.id === CONSOLE_LOG_ID) console.log('after logic eval', result);
                    return result;
                };

                let evaluatedValue = Array.isArray(logicValue)
                    ? logicValue.map(item => evalLogic(item, problem))
                    : evalLogic(logicValue, problem);
                // if (problem.id === CONSOLE_LOG_ID) console.log('evaluatedValue', logicPath, evaluatedValue);

                if (logicPath.startsWith('variables')) {
                    evaluatedValue = Number(evaluatedValue);
                    // if (problem.id === CONSOLE_LOG_ID) console.log('TYPECAST', 'logicPath', logicPath, 'logicValue', logicValue, 'evaluatedValue', evaluatedValue);
                }

                // consoleLogId(problem.id, `skipLogic ${skipLogic}`);
                // consoleLogId(problem.id, `currentPath ${currentPath}`);
                const shouldSkip = Array.isArray(skipLogic) ? skipLogic.some(skip => checkSkipLogic(currentPath, skip))
                    : checkSkipLogic(currentPath, skipLogic);

                if (Array.isArray(currentValue)) {
                    evaluatedValue.forEach((val, index) => {
                        if (index < currentValue.length && !shouldSkip) {
                            currentValue[index] = val;
                            // if (problem.id === CONSOLE_LOG_ID) console.log('array', currentValue, index, val);
                        }
                    });
                } else {
                    if (!shouldSkip) {
                        obj[baseKey] = evaluatedValue;
                        // if (problem.id === CONSOLE_LOG_ID) console.log('obj', obj, baseKey, currentValue, evaluatedValue, logicFields);
                    }
                }
            } catch (error) {
                console.error(`Error evaluating logicValue for problem ${problem.name} ${problem.id} at ${logicPath}: ${logicValue}`, problem, error);
            }
        };

        const applyRefFields = (refFields, problem) => {
            refFields.forEach((refField) => {
                applyRefField(refField, problem);
            });
        };

        const applyRefField = (refField, problem) => {
            const { logicPath, currentPath, logicValue, currentValue, obj } = refField;
            try {
                const safeEval = new Function('problem', `
                    with (problem) {
                        try {
                            return \`${logicValue}\`;
                        } catch (e) {
                            console.warn('Error during evaluation:', e, logicValue);
                            return undefined;
                        }
                    }
                `);
                
                const result = Number(safeEval(problem));
                // if (problem.id === CONSOLE_LOG_ID) console.log('result of', currentPath, 'with ref', logicValue, '=', result);
                problem.variables[currentPath] = result;
            } catch (error) {
                console.error(`Error evaluating refValue for problem ${problem.name} ${problem.id} at ${logicPath}: ${logicValue}`, problem, error);
            }
        };

        const applyFields = (fields, problem, skipLogic) => {
            fields.forEach((field) => {
                if (field.type === 'logic') {
                    applyLogicField(field, problem, skipLogic);
                } else if (field.type === 'ref') {
                    applyRefField(field, problem);
                }
            });
        };

        const filterFields = (logicFields, contains) => {
            let fieldsWithInteraction = new Set();
            let fieldsWithoutInteraction = new Set();
            let fieldsWithInteractionToCheck = [];
                
            // Check dependencies and add to interaction/non-interaction sets
            const checkContains = (field, contains, visited) => {
                const { logicValue, currentPath } = field;
                // if (problem.id === CONSOLE_LOG_ID) console.log('checkContains', contains, 'on field', logicValue);
        
                // Prevent circular dependency by marking the field as visited
                if (visited.has(currentPath)) {
                    // console.warn(`Already visited ${currentPath}, skipping.`);
                    return null;
                } else {
                    visited.add(currentPath);
                }

                let doesContain = `${logicValue}`.includes(contains); // Check if the current field matches the condition
                if (contains.includes('variables')) {
                    // if (problem.id === CONSOLE_LOG_ID) console.log('checkContains', contains, 'includes variables');
                    const containsWithoutVariables = contains.replace('variables.', '');
                    doesContain = `${logicValue}`.includes(containsWithoutVariables) || `${logicValue}`.includes('variables');
                    // if (problem.id === CONSOLE_LOG_ID) console.log('new check', containsWithoutVariables, 'doesContain', doesContain);
                }
                
                if (doesContain) {
                    // console.log(`TRUE currentPath "${currentPath}" with logicValue "${logicValue}" contains "${contains}"`);
                    fieldsWithInteraction.add(field);
                    fieldsWithInteractionToCheck.push(field); // Store the field for later dependency check
                    fieldsWithoutInteraction.delete(field); // Remove from non-interaction set
                } else {
                    // console.log(`FALSE currentPath "${currentPath}" with logicValue "${logicValue}" does not contain "${contains}"`);
                    if (!fieldsWithInteraction.has(field)) {
                        fieldsWithoutInteraction.add(field);
                    }
                }
        
                return { doesContain };
            };
                
            // Extract references from logicValue and recursively check references
            const checkNestedDependencies = (logicFields, dependency, dependenciesAlreadyChecked) => {
                let { logicValue, currentPath } = dependency;
                currentPath = convertDotToBracketSyntax(currentPath);
                // console.log('currentPath', currentPath);

                if (typeof currentPath === 'string') {
                    const visited = new Set(); // Reset visited for each nested dependency check
                    // console.log(`Recursively checking field: ${currentPath} with visited`, visited);
                    
                    // Run checkContains on all logicFields for each reference
                    logicFields.forEach(logicField => {
                        const { doesContain } = checkContains(logicField, currentPath, visited);
                        // If a new interaction field is found, check its dependencies
                        if (doesContain) {
                            if (!dependenciesAlreadyChecked.has(logicField.currentPath)) {
                                // console.log(`NEW interaction field found: ${logicField.currentPath}, checking dependencies.`);
                                dependenciesAlreadyChecked.add(logicField.currentPath);
                                checkNestedDependencies(logicFields, logicField, dependenciesAlreadyChecked); // Recursive call for the newly found field
                            } else {
                                // console.warn(`Field ${logicField.currentPath} has already been checked, skipping. ${dependency}`);
                            }
                        } else {
                            // console.log(`Field ${logicField.currentPath} does not contain ${currentPath}.`);
                        }
                    });
                    dependenciesAlreadyChecked.add(currentPath); // Mark this field as checked
                } else {
                    // console.warn(`dependency currentPath ${currentPath} not a string, type ${typeof currentPath}`);
                }
            };

            // First, perform checkContains on all fields
            if (Array.isArray(contains)) { // if contains is an array, such as x,y coordinate for line graph
                contains.forEach((c) => {
                    const visitedInitial = new Set();
                    logicFields.forEach(field => {
                        checkContains(field, c, visitedInitial);
                    });
                });
            } else {
                const visitedInitial = new Set();
                logicFields.forEach(field => {
                    checkContains(field, contains, visitedInitial);
                });
            }
        
            // Then check nested dependencies
            const dependenciesAlreadyChecked = new Set();
            dependenciesAlreadyChecked.add(contains);
            fieldsWithInteractionToCheck.forEach(dependency => {
                checkNestedDependencies(logicFields, dependency, dependenciesAlreadyChecked);
            });
        
            return {
                fieldsWithInteraction: Array.from(fieldsWithInteraction),
                fieldsWithoutInteraction: Array.from(fieldsWithoutInteraction),
                checked: Array.from(dependenciesAlreadyChecked),
            };
        };
        
        if (skipLogic) {
            const refFields = findRefFields(problem.variables);
            const allLogicFields = findLogicFields(problem, '');
            const allFields = [...allLogicFields, ...refFields];
            const { fieldsWithInteraction, fieldsWithoutInteraction, checked } = filterFields(allFields, skipLogic);

            applyFields(fieldsWithInteraction, problem, skipLogic);
            // applyLogicFields(fieldsWithoutInteraction, problem, null);

            applyLogicFields(allLogicFields, problem, null);

            // if (problem.id === CONSOLE_LOG_ID) {
            //     console.log(problem);
            //     console.log(`SKIPLOGIC ${skipLogic} \n${problem.id}`);
            //     console.log('refFields', refFields);
            //     console.log('allLogicFields', allLogicFields.length+1, problem.id, allLogicFields);
            //     console.log('allFields', allFields.length+1, problem.id, allFields);
            //     console.log('fieldsWithInteraction', fieldsWithInteraction.length+1, problem.id, fieldsWithInteraction);
            //     console.log('fieldsWithoutInteraction', fieldsWithoutInteraction.length+1, problem.id, fieldsWithoutInteraction);
            //     console.log('checked', checked.length+1, problem.id, checked);
            //     console.log(problem);
            // }
        } else {
            const refFields = findRefFields(problem.variables);
            applyRefFields(refFields, problem);

            const logicFields = findLogicFields(problem, '');
            applyLogicFields(logicFields, problem, null);

            // if (problem.id === CONSOLE_LOG_ID) {
            //     console.log('initial variables', problem.variables);
            //     console.log('refFields', refFields);
            //     console.log('logicFields', logicFields.length+1, problem.id, logicFields);
            //     console.log('problem', problem);
            // }
        }
        
        return problem;        
    };

    let updatedProblem = { ...problem };
    if (updatedProblem.parts && Array.isArray(updatedProblem.parts)) {
        updatedProblem = applyLogic(problem, skipLogic);
        updatedProblem.parts = transformExpressions(updatedProblem.parts);
        updatedProblem.parts = transformVisualizations(updatedProblem.parts, updatedProblem.colorOverride);
    }

    // if (problem.id === CONSOLE_LOG_ID) console.log("UPDATE transformed problem data", updatedProblem); 
    return updatedProblem;
};

const CONSOLE_LOG_ID = 'simplified-ratios';
const consoleLogId = (id, log) => {
    if (CONSOLE_LOG_ID && CONSOLE_LOG_ID === id) {
        console.log(log);
    } else {
        return;
    }
};

const consoleLogDetail = (problem) => {
    problem.parts.forEach((part, partIndex) => {
        if (part.answer && part.answer.validationPath) {
            console.log('TransformContent: validation', part.answer.validation);
            console.log('TransformContent: validationPath', part.answer.validationPath);
        }
    });

    // problem.parts.forEach((part, partIndex) => {
    //     part.visualizations.forEach((visualization, vizIndex) => {
    //         if (part.text.includes('(c)')) {
    //             if (visualization.type === 'lineGraph' || visualization.type === 'barGraph') {
    //                 console.log(part.text, problem.id, visualization);
    //             }
    //         }
    //     });
    // });
};





const convertBracketToDotSyntax = (path) => {
    const dotSyntax = path.replace(/\[(\d+)\]/g, '.$1');
    // consoleLogId(problem.id, `Original bracket syntax: ${path}`);
    // consoleLogId(problem.id, `Converted to dot syntax: ${dotSyntax}`);
    return dotSyntax;
};

const convertDotToBracketSyntax = (path) => {
    const bracketSyntax = path.replace(/\.(\d+)/g, '[$1]');
    // consoleLogId(problem.id, `Original dot syntax: ${path}`);
    // consoleLogId(problem.id, `Converted to bracket syntax: ${bracketSyntax}`);
    return bracketSyntax;
};

// hoveredColor ? adjustColorForHover(innerItem.original) : innerItem.original
// const adjustColorForHover = (color: string) => {
//     if (hoveredColor && color.toLowerCase() !== hoveredColor.toLowerCase()) {
//       return Colors.grey2;
//     }
//     return color;
// };

const getImageBasePath = (basePath) => {
    // Check if the basePath already includes '/images/' to prevent reprocessing
    if (typeof basePath === 'string' && !basePath.startsWith('/images/')) {
        let path = `${process.env.PUBLIC_URL}/images/${basePath}`;
        return path;
    }
    return basePath;
};

const getImageFullPath = (basePath, color, colorOverride) => {
    // console.log('base path', basePath, color);
    let path = basePath;
    let colorName = null;
    if (color) colorName = getColorNameByValue(color);
    if (colorName && colorOverride && color !== Colors.grey6 && color !== Colors.grey2) path = `${basePath}.png`;
    else if (colorName) path = `${basePath}-${colorName}.png`;
    return path;
};

const getColorNameByValue = (value: string) => {
    if (value.toLowerCase() === Colors.grey2.toLowerCase()) {
        return 'grey2';
    } else if (value.toLowerCase() === Colors.grey6.toLowerCase()) {
        return 'grey6';
    }

    let entry = Object.entries(getColorViz).find(([_, v]) => v?.toLowerCase() === value?.toLowerCase());
    if (!entry) {
      const colorVizEntry = getColorViz().find(color => color.value?.toLowerCase() === value?.toLowerCase());
      if (colorVizEntry) {
        return colorVizEntry.name.toLowerCase();
      }
    }
    return entry ? entry[0] : value;
};

const getColorValueByName = (name: string) => {
    if (name.toLowerCase() === Colors.grey2.toLowerCase()) {
        return Colors.grey2;
    }

    let entry = Object.entries(getColorViz).find(([n]) => n?.toLowerCase() === name?.toLowerCase());
    if (!entry) {
        const colorVizEntry = getColorViz().find(color => color.name?.toLowerCase() === name?.toLowerCase());
        if (colorVizEntry) {
        return colorVizEntry.value;
        }
    }
    return entry ? entry[1] : name;
};
// Dragger.tsx
import * as d3 from 'd3';
import Hammer from 'hammerjs';
import { roundToPrecision } from '../../../utils/beautifyText';
import Fonts from '../../common/Fonts';
import { findPointOnLine } from '../LineGraph';

interface DraggerProps {
  svgRef: React.RefObject<SVGSVGElement>;
  id?: string;
  color?: string;
  direction?: 'up' | 'down' | 'left' | 'right' | 'all' | 'valid' | 'clockwise' | 'both';
  tutorial?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right' | null;
  tutorialOffset?: number;

  x: number;
  y: number;
  minPos?: number | { x: number, y: number};
  maxPos?: number | { x: number, y: number};
  center?: { x: number, y: number};
  radius?: number;

  value: number | { x: number, y: number};
  prevSumValue?: number;
  postSumValue?: number;
  tick?: number;
  tickOffset?: number;
  minVal: number | { x: number, y: number};
  maxVal: number | { x: number, y: number};
  decimalPrecision?: number;
  showLabel?: 'top' | 'bottom';
  dragOverrideOtherValues?: boolean;
  lineGraphDataset?: any;
  actualScale?: number;

  handleDrag?: (newValue?: number, newX?: number, newY?: number) => void;
  startDrag?: () => void;
  endDrag?: () => void;
  startHover?: () => void;
  endHover?: () => void;
}

const ANIMATE = true;

const Dragger = ({ svgRef, id, color = 'black', direction, x, y, minPos, maxPos, tutorial=null, tutorialOffset=0,
  value, prevSumValue = 0, postSumValue = 0, tick, tickOffset, minVal, maxVal, decimalPrecision, showLabel, dragOverrideOtherValues, lineGraphDataset,
  handleDrag, startDrag, endDrag, startHover, endHover, center = { x: 0, y: 0 }, radius, actualScale = 1 }: DraggerProps) => {

    const draggerRadius = 10;
    const draggerHoverRadius = 12;
    const draggerStrokeWidth = 2;
    const draggerOuterStrokeWidth = 20;
    const scaledRadius = draggerRadius / actualScale;
    const scaledStrokeWidth = draggerStrokeWidth / actualScale;
    const scaledOuterStrokeWidth = draggerOuterStrokeWidth / actualScale;

    if (!svgRef.current) return;
    const svg = d3.select(svgRef.current);
    let dragger, outer, inner, label: any;
    let newX = x;
    let newY = y;

    const getSvgPoint = (event) => {
      const point = svgRef.current!.createSVGPoint();
      point.x = event.center.x;
      point.y = event.center.y;
      const screenCTM = svgRef.current!.getScreenCTM();
      return point.matrixTransform(screenCTM.inverse());
    };
  
    const onDrag = (event) => {
      const pos = getSvgPoint(event);
      // console.log('onDrag', pos.x, pos.y);
      if (direction === 'up' || direction === 'down') {
        newY = pos.y;
        handleDrag(findNewValueFromPos(newY));
      } else if (direction === 'left' || direction === 'right') {
        newX = pos.x;
        handleDrag(findNewValueFromPos(newX));
      } else if (direction === 'all' || direction === 'both') {
        newX = pos.x;
        newY = pos.y;
        handleDrag(findNewValueFromPos(newX, 'x'), findNewValueFromPos(newY, 'y'));
      } else if (direction === 'valid') {
        newX = pos.x;
        newY = pos.y;
        const targetX = findNewValueFromPos(newX, 'x');
        const targetY = findNewValueFromPos(newY, 'y');
        let closestPoint = findPointOnLine(lineGraphDataset, targetX, targetY, true);
        if (closestPoint !== null) {
          handleDrag(closestPoint.x, closestPoint.y);
          // console.log('targetX', targetX, 'targetY', targetY, 'new coordinate', closestPoint.x, closestPoint.y);
        }
      } else if (direction === 'clockwise') {
        const angle = Math.atan2(pos.y - center.y, pos.x - center.x);
        newX = center.x + radius * Math.cos(angle);
        newY = center.y + radius * Math.sin(angle);
        // console.log('angle', angle, 'newX', newX, 'newY', newY);
        handleDrag(findNewValueFromAngle(angle));
      }
      // console.log('onDrag newX:', newX, 'newY:', newY);
    };
  
    const findNewValueFromAngle = (angle) => {
      angle -= Math.PI / 2; // -π to π
    
      const proportion = (angle < -Math.PI) ? 
        (angle + 2 * Math.PI + Math.PI) / (2 * Math.PI) :
        (angle + Math.PI) / (2 * Math.PI);
    
      let newValue = (minVal as number) + proportion * ((maxVal as number) - (minVal as number));
      newValue -= prevSumValue;

      if (dragOverrideOtherValues) {
        newValue = roundValue(newValue, minVal, maxVal);
        // console.log('override', 'new value', newValue, 'min', minVal, 'max', maxVal);
      } else {
        const { min, max } = calcMinMaxForValue(newValue, 'single');
        newValue = roundValue(newValue, min, max);
        // console.log('new value', newValue, 'min', min, 'max', max);
      }

      // console.log('angle', angle, 'proportion', proportion, 'value', value, 'new value', newValue);
      return newValue;
    };          
  
    const findNewValueFromPos = (pos, axis = 'single') => {
      let cappedPos, minP, maxP, minV, maxV;
  
      if (axis === 'x' && typeof minPos !== 'number' && typeof maxPos !== 'number') {
        minP = minPos.x;
        maxP = maxPos.x;
      } else if (axis === 'y' && typeof minPos !== 'number' && typeof maxPos !== 'number') {
        minP = minPos.y;
        maxP = maxPos.y;
      } else {
        minP = minPos as number;
        maxP = maxPos as number;
      }
  
      cappedPos = Math.max(minP, Math.min(pos, maxP));
      // console.log(axis, 'pos', pos, 'cappedPos', cappedPos, 'minP', minP, 'maxP', maxP);
  
      if (axis === 'x' && typeof minVal !== 'number' && typeof maxVal !== 'number') {
        minV = minVal.x;
        maxV = maxVal.x;
      } else if (axis === 'y' && typeof minVal !== 'number' && typeof maxVal !== 'number') {
        minV = minVal.y;
        maxV = maxVal.y;
      } else {
        minV = minVal as number;
        maxV = maxVal as number;
      }
  
      let proportion;
      if (direction === 'down' || direction === 'right') {
        proportion = (cappedPos - minP) / (maxP - minP);
      } else if (direction === 'up' || direction === 'left') {
        proportion = (maxP - cappedPos) / (maxP - minP);
      } else if (direction === 'both') {
        if (axis === 'x') {
          proportion = (cappedPos - minP) / (maxP - minP);
        } else if (axis === 'y') {
          proportion = (cappedPos - minP) / (maxP - minP);
        }
      } else if (direction === 'all' || direction === 'valid') {
        if (axis === 'x') {
          proportion = (cappedPos - minP) / (maxP - minP);
        } else if (axis === 'y') {
          proportion = (maxP - cappedPos) / (maxP - minP);
        }
      }
      let newValue = minV - prevSumValue + proportion * (maxV - minV);
      // console.log(axis, 'direction', direction, 'proportion', proportion);
      // console.log('prevSumValue', prevSumValue);
      
      if (dragOverrideOtherValues) {
        newValue = roundValue(newValue, minV, maxV);
        // console.log('override', 'new value', newValue, 'min', minV, 'max', maxV);
      } else if (direction === 'valid') {
        // don't round it for line graph
      } else {
        const { min, max } = calcMinMaxForValue(newValue, axis);
        newValue = roundValue(newValue, min, max);
        // console.log('new value', newValue, 'min', min, 'max', max);
      }
      
      return newValue;
    };
  
    const roundValue = (newValue, min, max) => {
      let roundedNewValue = newValue;
    
      if (tick && decimalPrecision !== undefined) {
        if (tickOffset) roundedNewValue = (roundedNewValue - tickOffset);

        roundedNewValue = Math.round(roundedNewValue / tick) * tick;
        roundedNewValue = roundToPrecision(roundedNewValue, decimalPrecision);

        if (tickOffset) roundedNewValue += tickOffset;
        // console.log('drag tick', tick, 'tick offset', tickOffset, 'precision', decimalPrecision, 'rounded new value', roundedNewValue);
      }
      if (roundedNewValue > max && tick !== 1) {
        // console.log('roundedNewValue', roundedNewValue, 'tick', tick, 'roundedNewValue + tick', roundedNewValue + tick, 'max', max);
        roundedNewValue = roundedNewValue - tick;
      }
      const cappedNewValue = Math.max(min, Math.min(roundedNewValue, max));
      // console.log('VALUE', value, 'NEW VALUE', newValue, 'ROUNDED', roundedNewValue, 'CAPPED', cappedNewValue);
      return cappedNewValue;
    };

    const calcMinMaxForValue = (newValue, axis = 'single') => {
      let minV, maxV;
  
      if (axis === 'x' && typeof minVal !== 'number' && typeof maxVal !== 'number') {
        minV = minVal.x;
        maxV = maxVal.x;
      } else if (axis === 'y' && typeof minVal !== 'number' && typeof maxVal !== 'number') {
        minV = minVal.y;
        maxV = maxVal.y;
      } else {
        minV = minVal as number;
        maxV = maxVal as number;
      }
  
      const sumValue = prevSumValue + newValue + postSumValue;
      // console.log('prevSumValue', prevSumValue, 'postSumValue', postSumValue, 'sumValue', sumValue);
      const min = minV;
      const max = maxV - prevSumValue - postSumValue;
      // console.log('minVal', minVal, 'maxVal', maxVal, 'minCurrent', min, 'maxCurrent', max);
      return { min, max };
    };
  
    const render = () => {
      dragger = svg.append('g')
        .attr('transform', `translate(${x},${y})`)
        .style('pointer-events', 'none');

      if (ANIMATE) {
        const base = dragger.append('circle')
          .attr('cx', 0)
          .attr('cy', 0)
          .attr('r', scaledRadius)
          .attr('fill', 'black')
          .attr('stroke', 'black')
          .attr('stroke-width', scaledOuterStrokeWidth)
          .attr('stroke-opacity', 0.2);
      }
  
      outer = dragger.append('circle')
        .attr('cx', 0)
        .attr('cy', 0)
        .attr('r', scaledRadius)
        .attr('fill', 'black')
        .attr('stroke', 'black')
        .attr('stroke-width', scaledOuterStrokeWidth)
        .attr('stroke-opacity', 0.2)
        .style('pointer-events', 'auto')
        .style('cursor', 'pointer');

      if (ANIMATE) pulseAnimation();

      inner = dragger.append('circle')
        .attr('cx', 0)
        .attr('cy', 0)
        .attr('r', scaledRadius)
        .attr('stroke', 'black')
        .attr('stroke-width', scaledStrokeWidth)
        .attr('fill', color)
        .style('pointer-events', 'auto')
        .style('cursor', 'pointer');

      renderTutorial();
    };

    const pulseAnimation = () => {
      outer
        .interrupt()
        .attr('stroke-width', 0)
        .style('stroke-opacity', 0.8)
        .transition()
        .duration(1500)
        .attrTween('stroke-width', () => d3.interpolate(0, scaledOuterStrokeWidth))
        .styleTween('stroke-opacity', () => d3.interpolate(0.8, 0))
        .on('end', pulseAnimation);
    };

    const stopAnimation = () => {
      outer
        .interrupt()
        .on('end', pulseAnimation)
        .attr('stroke-width', scaledStrokeWidth)
        .attr('stroke-opacity', 0.2);
    };

    const renderTutorial = () => {
      if (tutorial) {
        const baseImgSize = 36;
        const scaledImgSize = baseImgSize / actualScale;
        const img = '/ui-assets/interaction-arrow-' + tutorial + '.svg';
        const imgX = x;
        let imgY = tutorial.includes('top') ? y - scaledImgSize - 2/actualScale : tutorial.includes('bottom') ? y + scaledImgSize + 2/actualScale : y;
        const textX = tutorial.includes('left') ? x - scaledImgSize*1.3 : tutorial.includes('right') ? x + scaledImgSize*1.3 : x;
        let textY = tutorial.includes('top') ? y - scaledImgSize*1.4 : tutorial.includes('bottom') ? y + scaledImgSize*1.4 : y;
        imgY = imgY + tutorialOffset;
        textY = textY + tutorialOffset;
        
        svg.append('image')
          .attr('href', img)
          .attr('x', imgX - scaledImgSize/2)
          .attr('y', imgY - scaledImgSize/2)
          .attr('width', scaledImgSize)
          .attr('height', scaledImgSize);
        
        svg.append('text')
          .attr('x', textX)
          .attr('y', textY)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .style('font-family', Fonts.lexendMedium.fontFamily)
          .style('font-weight', Fonts.lexendMedium.fontWeight)
          .style('font-size', `${22/actualScale}px`)
          .attr('fill', 'black')
          .text('Drag');
      }
    };

    const renderLabel = () => {
      if (showLabel) {
        let labelText;
        if (typeof value === 'object' && 'x' in value && 'y' in value) {
          labelText = `(${value.x.toFixed(decimalPrecision)}, ${value.y.toFixed(decimalPrecision)})`;
        } else {
          labelText = value;
        }
        const basePos = showLabel === 'top' ? -22 : 22;
        const scaledPos = basePos / actualScale;
        const baseFontSize = showLabel === 'top' ? 24 : 18;
        const scaledFontSize = baseFontSize / actualScale;
        
        label = dragger.selectAll('text').data([labelText]);
        label.enter().append('text')
          .attr('x', 0)
          .attr('y', scaledPos) 
          .attr('text-anchor', 'middle')
          .style('font-family', Fonts.lexendMedium.fontFamily)
          .style('font-weight', Fonts.lexendMedium.fontWeight)
          .style('font-size', `${scaledFontSize}px`)
          .attr('fill', color)
          .merge(label)
          .text(labelText);
      }
    };

    const removeLabel = () => {
      dragger.selectAll('text').remove();
    }
  
    const applyHover = (element, outer, inner, color) => {
      const handleHover = (radius, callback) => {
        if (callback) callback();
        outer.transition().duration(100).attr('r', radius/actualScale);
        inner.transition().duration(100).attr('r', radius/actualScale);
        // inner.transition().duration(100).attr('fill', radius === 10 ? color : 'black');  // Change fill based on hover state
        renderLabel();
      };
  
      element
        .on("mouseover", () => {
          handleHover(draggerHoverRadius, startHover);
          if (ANIMATE) stopAnimation();
        })
        .on("mouseout", () => {
          handleHover(draggerRadius, endHover);
          if (ANIMATE) pulseAnimation();
          removeLabel();
        });
    };
  
    render();
    applyHover(outer, outer, inner, color);
    applyHover(inner, outer, inner, color);
  
    const applyHammer = (draggerNode) => {
      const hammer = new Hammer(draggerNode);
      hammer.get('pan').set({ direction: Hammer.DIRECTION_ALL });
  
      let initialX = x;
      let initialY = y;
      let startPos: { x: number, y: number } | null = null;
  
      hammer.on('panstart', (event) => {
        event.srcEvent.preventDefault();
        if (startDrag) startDrag();
        startPos = getSvgPoint(event);
        initialX = x;
        initialY = y;
        newX = x;
        newY = y;
        renderLabel();
      });
  
      hammer.on('panmove', (event) => {
        event.srcEvent.preventDefault();
        if (!startPos) return;
        
        const currentPos = getSvgPoint(event);
        const dx = currentPos.x - startPos.x;
        const dy = currentPos.y - startPos.y;
        
        newX = initialX + dx;
        newY = initialY + dy;
        
        onDrag(event);
        renderLabel();
      });
  
      hammer.on('panend', (event) => {
        event.srcEvent.preventDefault();
        if (endDrag) endDrag();
        startPos = null;
        if (ANIMATE) pulseAnimation();
        removeLabel();
      });
  
      draggerNode.addEventListener('touchstart', (event) => event.preventDefault(), { passive: false });
      draggerNode.addEventListener('touchmove', (event) => event.preventDefault(), { passive: false });
      draggerNode.addEventListener('touchend', (event) => event.preventDefault(), { passive: false });
    };
  
    const draggerNode = dragger.node();
    if (draggerNode) {
      applyHammer(draggerNode);
    }
  
    return null;
  };
  
  export default Dragger;
// Table.tsx
import React, { useState } from 'react';
import styled from 'styled-components';
import Fonts from '../common/Fonts';
import Colors from '../common/Colors';
import { useHover } from '../../pages/problem/HoverContext';
import { convertAllTypes, ValueType, MatchValueType, getDefaultValueForType } from '../../utils/convertTypes';

export interface TableProps {
  title?: string;
  columns?: {
    title: { value: string, type: ValueType, color?: string },
    data: { value: MatchValueType<ValueType, any>, color?: string, emphasize?: boolean }[]
  }[];
  rows?: {
    title: { value: string, type: ValueType, color?: string },
    data: { value: MatchValueType<ValueType, any>, color?: string, emphasize?: boolean }[]
  }[];
  onStateChange?: (state: TableProps) => void;
}

function generateTablePropsTypeDefinition(props: TableProps): TableProps {
  const columns = props.columns?.map(column => ({
    title: {
      value: column.title.value,
      type: column.title.type,
      color: column.title.color || ''
    },
    data: column.data.map(dataItem => ({
      value: getDefaultValueForType(column.title.type) as MatchValueType<ValueType, any>,
      color: dataItem.color || '',
      emphasize: dataItem.emphasize || false
    }))
  })) || [];

  const rows = props.rows?.map(row => ({
    title: {
      value: row.title.value,
      type: row.title.type,
      color: row.title.color || ''
    },
    data: row.data.map(dataItem => ({
      value: getDefaultValueForType(row.title.type) as MatchValueType<ValueType, any>,
      color: dataItem.color || '',
      emphasize: dataItem.emphasize || false
    }))
  })) || [];

  return {
    columns,
    rows,
    onStateChange: props.onStateChange || (() => {})
  };
};        

const TableRoot = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: none;
  margin: 10px 0;
`;

const StyledTitle = styled.h3`
  font-family: ${Fonts.lexendSemiBold.fontFamily};
  font-weight: ${Fonts.lexendSemiBold.fontWeight};
  font-size: 21px;
  line-height: 1.4em;
  text-align: center;
  background: none;
  margin: 0px 0px 20px 0px;
`;

const StyledTable = styled.table`
  display: block;
  border-collapse: collapse;
  text-align: center;
  height: 100%;
  font-family: ${Fonts.lexendMedium.fontFamily};
  font-weight: ${Fonts.lexendMedium.fontWeight};
  font-size: 18px;
  background-color: ${Colors.white};
  overflow-x: auto;
  // white-space: nowrap;
  // wrap-text: wrap;

  th,
  td {
    border: 1px solid #ddd;
    padding: 12px 16px;
    min-width: 60px;
    font-family: ${Fonts.lexendMedium.fontFamily};
    font-weight: ${Fonts.lexendMedium.fontWeight};
    vertical-align: middle;
  }

  th {
    background-color: #f2f2f2;
    vertical-align: bottom;
    font-family: ${Fonts.lexendBold.fontFamily};
    font-weight: ${Fonts.lexendBold.fontWeight};
  }

  th.row-header {
    background-color: white;
  }

  @media (max-width: 600px) {
    th,
    td {
      padding: 8px 10px;
      font-size: 16px;
    }
  }
`;

const Table: React.FC<TableProps> = (props) => {
  const tablePropsTypeDefinition = generateTablePropsTypeDefinition(props);
  props = convertAllTypes(props, tablePropsTypeDefinition);
  const { title, columns, rows, onStateChange } = props;
  // console.log(columns, rows);

  const { handleMouseEnter, handleMouseLeave } = useHover();
  const [highlightedCell, setHighlightedCell] = useState<{ row: number; col: number } | null>(null);

  const updateState = (newState: Partial<TableProps>) => {
    const updatedState = { ...props, ...newState };
    if (onStateChange) {
      onStateChange(updatedState);
    }
  };

  const handleCellClick = (row: number, col: number, color: string | undefined) => {
    if (highlightedCell) {
      handleMouseLeave();
      setHighlightedCell(null);
    } else {
      handleMouseEnter(color);
      setHighlightedCell({ row, col });
    }
  };

  return (
    <TableRoot>
      {title && <StyledTitle>{title}</StyledTitle>}
      <StyledTable>
        <tbody>
          {columns ? (
            <>
              <tr key={'title'}>
                {columns.map((column, colIndex) => (
                  <th key={colIndex}>
                    <span style={{ color: column.title.color }}
                      onClick={() => handleCellClick(-1, colIndex, column.title.color)}
                    >
                      {column.title.value}
                    </span>
                  </th>
                ))}
              </tr>
              {columns[0]?.data.map((_, rowIndex) => (
                <tr key={rowIndex}>
                  {columns.map((column, colIndex) => {
                    // console.log(`Column Title: ${column.title.value}, Column ${colIndex}, Row ${rowIndex}: ${typeof column.data[rowIndex].value}, Expected Type: ${column.title.type}`);
                    return (
                      <td key={colIndex}>
                        <span style={{
                            color: column.data[rowIndex].color,
                            fontWeight: column.data[rowIndex].emphasize ? 'bold' : 'medium'
                          }}
                          onClick={() => handleCellClick(rowIndex, colIndex, column.data[rowIndex].color)}
                        >
                          {column.data[rowIndex].value}
                        </span>
                      </td>
                    );
                  })}
                </tr>
              ))}
            </>
          ) : 
          rows ? (
            <>
              <tr key={'header-row'}>
                {rows[0] && (
                  <>
                    <th>
                      <span style={{ color: rows[0].title.color }}
                        onClick={() => handleCellClick(0, -1, rows[0].title.color)}
                      >
                        {rows[0].title.value}
                      </span>
                    </th>
                    {rows[0].data.map((dataItem, colIndex) => {
                      // console.log(`Row Header Title: ${rows[0].title.value}, Header Row, Column ${colIndex}: ${typeof dataItem.value}, Expected Type: ${rows[0].title.type}`);
                      return (
                        <th key={colIndex}>
                          <span style={{
                              color: dataItem.color,
                              fontWeight: dataItem.emphasize ? 'bold' : 'medium'
                            }}
                            onClick={() => handleCellClick(0, colIndex, dataItem.color)}
                          >
                            {dataItem.value}
                          </span>
                        </th>
                      );
                    })}
                  </>
                )}
              </tr>
              {rows.slice(1).map((row, rowIndex) => (
                <tr key={rowIndex + 1}>
                  <th className="row-header">
                    <span style={{ color: row.title.color }}
                      onClick={() => handleCellClick(rowIndex + 1, -1, row.title.color)}
                    >
                      {row.title.value}
                    </span>
                  </th>
                  {row.data.map((dataItem, colIndex) => {
                    // console.log(`Row Title: ${row.title.value}, Row ${rowIndex + 1}, Column ${colIndex}: ${typeof dataItem.value}, Expected Type: ${row.title.type}`);
                    return (
                      <td key={colIndex}>
                        <span style={{ 
                            color: dataItem.color,
                            fontWeight: dataItem.emphasize ? 'bold' : 'medium'
                          }}
                          onClick={() => handleCellClick(rowIndex + 1, colIndex, dataItem.color)}
                        >
                          {dataItem.value}
                        </span>
                      </td>
                    );
                  })}
                </tr>
              ))}
            </>
          ) : null}
        </tbody>
      </StyledTable>
    </TableRoot>
  );
};

export default Table;
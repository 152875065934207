import { create, all } from 'mathjs';
import * as _ from 'lodash';

const math = create(all);

export const simplifyFraction = (num, den) => {
    let result = { n: num, d: den };

    try {
        if (den === 0) {
            result = { n: Infinity, d: 0 };
        } else {
            const fraction = math.fraction(num, den);
            const sign = num < 0 ? -1 : 1;
            fraction.n *= sign;
            result = { n: fraction.n, d: fraction.d };
        }
    } catch (e) {
        console.warn('Error in simplifyFraction:', e);
    }

    // console.log('fraction', num+'/'+den, 'simplified', result.n+'/'+result.d);
    return result;
};

export const fractionToMixed = (num, den) => {
    // console.log('fractionToMixed', { num, den });

    const simplified = simplifyFraction(num, den);
    num = simplified.n;
    den = simplified.d;

    try {
        // Handle invalid denominator case first
        if (den === 0) {
            const final = { w: 0, n: null, d: null };
            // console.log('original', num+'/'+den, 'mixed', final.w, final.n, '/', final.d);
            return final;
        }

        // Handle zero numerator case
        if (num === 0) {
            const final = { w: 0, n: null, d: null };
            // console.log('original', num+'/'+den, 'mixed', final.w, final.n, '/', final.d);
            return final;
        }

        // Convert to absolute values for calculation
        let numerator = Math.abs(num);
        let denominator = Math.abs(den);
        
        // Calculate whole number and remainder
        let whole = Math.floor(numerator / denominator);
        const remainder = numerator % denominator;
        
        // Handle sign
        if (num < 0 || den < 0) {
            whole = num * den < 0 ? -whole : whole;
            // console.log('original', num+'/'+den, 'mixed', whole, remainder, '/', denominator);
        }

        // If remainder is 0, it's a whole number
        if (remainder === 0) {
            const final = { w: whole, n: null, d: null };
            // console.log('original', num+'/'+den, 'mixed', final.w, final.n, '/', final.d);
            return final;
        }

        // If there's a remainder, return mixed number or proper fraction
        const final = {
            w: whole === 0 ? null : whole,
            n: num < 0 && whole === null ? -remainder : remainder,
            d: denominator
        };
        // console.log('original', num+'/'+den, 'mixed', final.w, final.n, '/', final.d);
        return final;
    } catch (e) {
        console.warn('Error in fractionToMixed:', e);
        const final = { w: null, n: num, d: den };
        // console.log('original', num+'/'+den, 'mixed', final.w, final.n, '/', final.d);
        return final;
    }
};

export const fractionString = ({ w, n, d }, latex = false, dfrac = true) => {
    // console.log('fractionString', { w, n, d }, latex, dfrac);
    try {
        let result = '';
        if (w !== null) {
            result += `${w}`;
            if (n !== null && d !== null) {
                if (latex === true) result += dfrac ? ` \\dfrac{${n}}{${d}}` : ` \\frac{${n}}{${d}}`;
                else result += ` ${n}/${d}`;
            }
        } else if (n !== null && d !== null) {
            result += `${n}/${d}`;
            if (latex === true) result = dfrac ? `\\dfrac{${n}}{${d}}` : `\\frac{${n}}{${d}}`;
        }
        // console.log('result', result);
        return result;
    } catch (e) {
        console.warn('Error in fractionString:', e);
        return `${n}/${d}`;
    }
};

export const greatestCommonFactor = (a, b) => {
    try {
        const gcf = math.gcd(a, b);
        // console.log('original', a+'/'+b, 'gcf', gcf);
        return gcf;
    } catch (e) {
        console.warn('Error in greatestCommonFactor:', e);
        return 1;
    }
};

export const getFactors = (a) => {
    try {
        const factors = [];
        for (let i = 1; i <= a; i++) {
            if (a % i === 0) {
                factors.push(i);
            }
        }
        // console.log('original', a, 'factors', factors);
        return factors;
    } catch (e) {
        console.warn('Error in factors:', e);
        return [0];
    }
};

export const commaForm = (value: number | string): string => {
    try {
        const newVal = value.toLocaleString();
        // console.log('value', value, 'newVal', newVal);
        return newVal;
    } catch (error) {
        console.warn('Error in commaForm:', error);
        return `${value}`; // Return the original value in case of error
    }
};

export const largeValueString = (value: number | string, precision?: number): string => {
    try {
        const numValue = typeof value === 'string' ? parseFloat(value) : value;
        let newVal: string = value.toString();
        
        if (Math.abs(numValue) >= 1000000000) {
            newVal = smartRound((numValue / 1000000000), precision) + 'B';
        } else if (Math.abs(numValue) >= 1000000) {
            newVal = smartRound((numValue / 1000000), precision) + 'M';
        } else if (Math.abs(numValue) >= 1000) {
            newVal = smartRound((numValue / 1000), precision) + 'k';
        } else {
            newVal = numValue.toString();
        }
        
        // console.log('value', value, 'newVal', newVal);
        return newVal;
    } catch (error) {
        console.warn('Error in largeValueString:', error);
        return `${value}`; // Return the original value in case of error
    }
}

export const smartRound = (value: number, precision: number): number => {
    try {
        const newVal = _.round(value, precision);
        // console.log('value', value, 'precision', precision, 'newVal', newVal);
        return newVal;
    } catch (error) {
        console.warn('Error in smartRound:', error);
        return value; // Return the original value in case of error
    }
};
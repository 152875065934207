import React, { useState, useEffect, useMemo, useCallback } from "react";
import styled from 'styled-components';
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { CalculatorFetch } from "../shared/ContentFetch";
import CalculatorContent from "./CalculatorContent";
import LoadingSpinner from "../../components/basic/LoadingSpinner";
import { StateProvider } from "../shared/State";
import Fonts from '../../components/common/Fonts';
import Colors from '../../components/common/Colors';
import Icon from "../../components/common/Icon";
import CalculatorLink from './CalculatorLink';
import ButtonLink from "../../components/basic/ButtonLink";
import { useGetProblemByUrl } from "../../apollo/useContent";
import { useConfig } from "../../utils/config";
import { Helmet } from 'react-helmet-async';
import CalculatorFooter from "./CalculatorFooter";

const Root = styled.div<{ $isMobile: boolean, $horizontalPadding: number }>`
    position: relative;
    width: 100%;
    height: 100svh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: ${Colors.background};
    color: ${Colors.grey8};
    font-family: ${Fonts.lexendMedium.fontFamily};
    font-weight: ${Fonts.lexendMedium.fontWeight};
    line-height: 1.4em;
    padding: ${props => props.$isMobile ? '20px 10px' : '20px 20px'};
    gap: 20px;
    overflow-y: auto;
`;

const ButtonContainer = styled.div`
    position: relative;
    left: -8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
`;

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    max-width: 1200px;
    gap: 10px;
    padding: 0 10px;
`;

const Header = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    max-width: 1200px;
    padding: 0 10px;
`;

const PageTitle = styled.h1`
    font-family: ${Fonts.lexendBold.fontFamily};
    font-weight: ${Fonts.lexendBold.fontWeight};
    font-size: 48px;
    line-height: 1em;
    text-align: left;
    margin: 14px 0 6px 0;
    padding-top: 24px;
    color: ${Colors.grey8};
`;

const Description = styled.p`
    font-family: ${Fonts.lexendSemiLight.fontFamily};
    font-weight: ${Fonts.lexendSemiLight.fontWeight};
    font-size: 18px;
    line-height: 1.3em;
    text-align: left;
    margin: 14px 0;
    color: ${Colors.grey8};
`;

const Section = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin: 14px 0;
`;

const SectionTitle = styled.h2`
    color: ${Colors.grey8};
    font-family: ${Fonts.lexendBold.fontFamily};
    font-weight: ${Fonts.lexendBold.fontWeight};
    font-size: 36px;
    line-height: 1.2em;
    margin: 12px 0;
`;

const CalculatorLinkGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 0px 40px;
    width: 100%;
`;

const Calculator: React.FC = () => {
  const config = useConfig();
  const navigate = useNavigate();
  const { calculatorId } = useParams<{ calculatorId: string }>();
  const location = useLocation();
  const { currentCalculator, fetchAndSetCalculatorByUrl, resetCurrentCalculator, menuCalculators, menuSections, findRelatedCalculators } = CalculatorFetch('calculator');

  const HORIZONTAL_PADDING = { default: 18, mobile: 10 };
  const horizontalPadding = window.innerWidth < 400 ? HORIZONTAL_PADDING.mobile : HORIZONTAL_PADDING.default;
  
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  useEffect(() => {
    const updateSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    if (calculatorId) {
      fetchAndSetCalculatorByUrl(calculatorId).then(calculator => {
        if (!calculator) {
          // If no calculator found, redirect to base calculator page
          navigate('/calculator', { 
            state: { from: location.pathname } 
          });
        } else {
          // Push current state to history to enable proper back navigation
          window.history.replaceState({ from: location.pathname }, '');
        }
      });
    } else {
      // Reset current calculator when on base route
      resetCurrentCalculator();
    }
  }, [calculatorId]);

  useEffect(() => {
    const handlePopState = (event: PopStateEvent) => {
      // If no calculatorId, handle navigation
      if (!calculatorId) {
        // Preserve previous navigation history if available
        if (event.state && event.state.from) {
          // Check if the previous route was a specific calculator
          const match = event.state.from.match(/^\/calculator\/(.+)$/);
          if (match) {
            navigate(event.state.from);
          } else {
            navigate('/calculator');
          }
        } else {
          navigate('/calculator');
        }
      }
    };

    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [calculatorId, navigate, location]);

  const memoizedInitialData = useMemo(() => {
    if (currentCalculator) return [currentCalculator];
    else return [];
  }, [currentCalculator]);

  const onProblemsClick = () => {
    navigate("/problem");
  };

  // If no calculatorId is provided, show the all calculators view
  if (!calculatorId) {
    return (
      <Root $isMobile={isMobile} $horizontalPadding={horizontalPadding}>
        <Helmet>
          <title>SeeSolve - Calculators & Tools</title>
          <meta name="description" content="Solve any math problem with interactive calculators and visualizations. Get step-by-step solutions and correct answers." />
        </Helmet>
        <Header>
          <ButtonContainer>
            <Link to="/problem" style={{ textDecoration: 'none' }}>
              <ButtonLink text={"All Problems"} icon={'home'} color={'blue'} size="medium" onClick={onProblemsClick}/>
            </Link>
          </ButtonContainer>
          <PageTitle>All Calculators & Tools</PageTitle>
          <Description>
            Solve math problems on any topic with these interactive calculators. Visualize concepts, understand relationships, and learn to solve similar problems with step-by-step solutions, formulas, and correct answers.
          </Description>
        </Header>
        <Container>
          {menuSections
            .filter(section => section !== 'WIP')
            .map(section => {
              const sectionCalculators = menuCalculators.filter(calc => 
                calc.section === section &&
                (config.SHOW_WIP_CALCULATORS || (!calc.name?.endsWith('(?)') && calc.id))
              );

              if (sectionCalculators.length === 0) return null;

              return (
                <Section key={section}>
                  <SectionTitle>{section}</SectionTitle>
                  <CalculatorLinkGrid>
                    {sectionCalculators.map((calculator, index) => (
                      <CalculatorLink 
                        key={calculator.name}
                        name={calculator.name} 
                        to={calculator.id ? `/calculator/${calculator.id}` : ''} 
                        icon={calculator.icon}
                      />
                    ))}
                  </CalculatorLinkGrid>
                </Section>
              );
            })}

          {/* WIP Section */}
          {menuSections.includes('WIP') && 
            (() => {
              const wipCalculators = menuCalculators.filter(calc => 
                calc.section === 'WIP' &&
                (config.SHOW_WIP_CALCULATORS || (!calc.name?.endsWith('(?)') && calc.id))
              );

              if (wipCalculators.length === 0) return null;

              return (
                <Section key="WIP">
                  <SectionTitle>Work in Progress</SectionTitle>
                  <CalculatorLinkGrid>
                    {wipCalculators.map((calculator, index) => (
                      <CalculatorLink 
                        key={calculator.name}
                        name={calculator.name} 
                        to={calculator.id ? `/calculator/${calculator.id}` : ''} 
                        icon={calculator.icon}
                      />
                    ))}
                  </CalculatorLinkGrid>
                </Section>
              );
            })()}
            
        </Container>
        <CalculatorFooter padding={`10px ${horizontalPadding}px 0px ${horizontalPadding}px`} maxWidth={1200} />
      </Root>
    );
  }

  // If calculatorId is provided, show the specific calculator
  return (
    <StateProvider key={currentCalculator?.id} initialData={memoizedInitialData} contentType="calculator">
      {currentCalculator && (
        <CalculatorContent 
          key={currentCalculator.id}
          currentCalculator={currentCalculator} 
          relatedCalculators={findRelatedCalculators(currentCalculator.id)}
          loading={false}
          isMobile={isMobile}
          horizontalPadding={horizontalPadding}
        />
      )}
   </StateProvider>
  );
};

export default Calculator;
